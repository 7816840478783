<template>
  <div class="etiqueta pt-3">
    <b-row alignV="center">
      <b-col cols="7">
        <div>
          <img class="pb-3" src="/logo.png" alt="" width="150px"/>
        </div>
      </b-col>
      <b-col cols="5">
        <div id="qrCode" class="text-center pb-4">
          <qrcode-vue
            :value="etiquetaData.code"
            level="H"
            class="qr-code"
            renderAs="svg"
          ></qrcode-vue>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="etiquetaData">
          <h3>OPME Consignado<span id="numero-material"> - {{ materialItemOfItems }}</span></h3>
          <b>Descrição:
          {{ etiquetaData.descricao }}
          </b>
          <br />
          <b>ID:
          {{ etiquetaData.shownId }}</b>
          <br />
          <b>Fornecedor:</b>
          {{ etiquetaData.fornecedor ? etiquetaData.fornecedor.nome : '' }}
          <br v-if="etiquetaData.procedimento" />
          <b v-if="etiquetaData.procedimento">Unidade:</b>
          {{
            (etiquetaData.procedimento && etiquetaData.procedimento.unidadeDestino)
              ? etiquetaData.procedimento.unidadeDestino.nome
              : ''
          }}
          <br v-if="etiquetaData.procedimento"/>
          <b v-if="etiquetaData.procedimento">Setor: </b>
          <span v-if="etiquetaData.procedimento">CCI</span>
          <br />
          <b>Patrimônio:</b> {{ etiquetaData.patrimonio }}
          <br v-if="etiquetaData.procedimento"/>
          <b v-if="etiquetaData.procedimento">Aviso:</b> {{ etiquetaData.procedimento ? etiquetaData.procedimento.aviso : '' }}
          <br v-if="etiquetaData.procedimento"/>
          <b v-if="etiquetaData.procedimento">Paciente:</b> {{ etiquetaData.procedimento ? etiquetaData.procedimento.paciente : '' }}
          <br v-if="etiquetaData.procedimento"/>
          <b v-if="etiquetaData.procedimento">Procedimento: </b> {{
            (etiquetaData.procedimento && etiquetaData.procedimento.procedimento_cirurgico)
              ? etiquetaData.procedimento.procedimento_cirurgico.substr(0, 132)
              : ''
          }}
          <br />
          <b v-if="etiquetaData.procedimento">Especialidade: </b> {{
            (etiquetaData.procedimento && etiquetaData.procedimento.especialidade)
              ? etiquetaData.procedimento.especialidade.substr(0, 85) : ''
          }}
          <br />
          <b v-if="etiquetaData.procedimento">Data/hora procedimento:</b> {{
            (etiquetaData.procedimento && etiquetaData.procedimento.data_procedimento)
              ? `${
                  moment(etiquetaData.procedimento.data_procedimento).format('DD/MM/YY')
                } ${etiquetaData.procedimento.horario_procedimento}h`
              : ''
          }}
          <br />
        </div>
        <div v-else>
          Ocorreu um problema ao gerar a etiqueta
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import QrcodeVue from 'qrcode.vue';

export default {
  props: {
    relatorioData: {
      type: Object,
      default() {
        return {};
      },
    },
    indexMaterial: {
      type: Number,
      default: 0,
    },
  },

  components: {
    QrcodeVue,
  },

  computed: {
    etiquetaData() {
      return this.relatorioData.materiais[this.indexMaterial];
    },
    materialItemOfItems() {
      const materialItem = +this.indexMaterial + 1;
      const materiaisTotal = this.relatorioData.materiais.length;
      return materialItem + '/' + materiaisTotal
    }
  },

  methods: {
    moment,
  },
};
</script>

<style scoped>
#numero-material {
  font-size: 1rem
}
.etiqueta {
  border-top: 10px solid #209f85;
  margin-left: 1cm !important;
}
#box-bloco-circulante {
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}
#div-title-bloco-circulante {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
}
#title-bloco-circulante {
  text-decoration: underline;
}
.underline {
  flex-grow: 1;
  /* width: 100%; */
  border-bottom: 1px solid black;
  margin-left: 5px;
  margin: 0 10px 4px 5px;
}
.sub-item-l {
  display: flex;
  width: 50%;
}
.l-BC {
  display: flex;
  margin-bottom: 10px;
}
</style>
